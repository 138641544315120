import { useMutation } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import omit from 'omit-deep-lodash';
import { useContext, useMemo } from 'react';
import { isBankAccountType } from '../components/paymentus/utils';
import usePgeQuery from '../hooks/usePgeQuery';
import useWrapWithLoader from '../hooks/useWrapWithLoading';
import { TransitionContext } from '../providers/TransitionProvider';
import {
  QuickPayDetails,
  QuickPayConfirmation,
} from '../__generated__/pge-types';

export const QUICKPAY_TOKEN_LS_KEY = 'quickpay-token';

export enum IneligibilityReason {
  undefined,
  'NoToken',
  'AutoPay',
  'Other',
  'NoPaymentMethod',
}

type Eligibility = {
  isEligible: boolean;
  ineligibilityReason: IneligibilityReason;
};

function getToken(querySearchParam: string): string {
  const x = querySearchParam.split('request=', 2);
  return x.length === 2 ? x[1] : '';
}
interface QuickPayHook {
  eligibility: Eligibility;
  loading: Boolean;
  data: QuickPayDetails | undefined;
  handleQuickPaySubmit: () => Promise<void>;
  result?: QuickPayConfirmation | undefined;
}

const quickPayQuery = gql`
  query getQuickPaymentDetails($encryptedAccountInfo: String!) {
    getQuickPaymentDetails(encryptedAccountInfo: $encryptedAccountInfo) {
      encryptedAccountNumber
      encryptedPersonId
      paymentEligibility {
        isCashOnly
        isNonBillableNoBalance
      }
      currentCharges {
        amountDue
        dueDate
      }
      autoPay {
        isEnrolled
        isSameDayEnrolled
        startDate
      }
      serviceAddress
      paymentDate
      emailId
      paymentProfile {
        token
        type
        accountNumber
        creditCardExpiryDate {
          month
          year
        }
        cardHolderName
        default
      }
    }
  }
`;

const quickPayMutation = gql`
  mutation submitQuickPay($payload: QuickPayInput!) {
    submitQuickPayment(payload: $payload) {
      confirmationId
      isSuccess
    }
  }
`;

export const useQuickPay = (): QuickPayHook => {
  const { wrapWithLoader } = useWrapWithLoader();
  const { setState: updateTransition } = useContext(TransitionContext);

  const token = useMemo(() => {
    return typeof window !== 'undefined'
      ? getToken(window.location.search)
      : '';
  }, []);

  const { data, loading: quickPayLoading, error } = usePgeQuery(quickPayQuery, {
    variables: {
      encryptedAccountInfo: token,
    },
    skip: !token,
    errorPolicy: 'all', // stop it going to default error page
  });

  const quickPayData: QuickPayDetails | undefined =
    data && data.getQuickPaymentDetails
      ? (omit(data.getQuickPaymentDetails, '__typename') as QuickPayDetails)
      : undefined;

  const [submitQuickPay, { data: submitResult }] = useMutation(
    quickPayMutation,
  );

  const handleQuickPaySubmit = wrapWithLoader(async () => {
    const quickPayInput = {
      encryptedAccountNumber: quickPayData?.encryptedAccountNumber,
      encryptedPersonId: quickPayData?.encryptedPersonId,
      tokenId: quickPayData?.paymentProfile?.token,
      emailId: quickPayData?.emailId,
      paymentAmount: quickPayData?.currentCharges?.amountDue,
    };
    await submitQuickPay({
      variables: {
        payload: quickPayInput,
      },
    });
  });

  // Check for no token ineligibility
  const eligibility: Eligibility = {
    isEligible: Boolean(token),
    ineligibilityReason: !token
      ? IneligibilityReason.NoToken
      : IneligibilityReason.undefined,
  };

  // Check for auto pay ineligibility
  if (quickPayData && quickPayData?.autoPay.isEnrolled) {
    eligibility.isEligible = false;
    eligibility.ineligibilityReason = IneligibilityReason.AutoPay;
  }

  // Check for 'other' ineligibility
  if (
    quickPayData &&
    (quickPayData?.paymentEligibility?.isNonBillableNoBalance ||
      (quickPayData?.paymentEligibility?.isCashOnly &&
        isBankAccountType(quickPayData?.paymentProfile?.type)) ||
      quickPayData?.currentCharges?.amountDue > 300000 ||
      quickPayData?.currentCharges?.amountDue <= 0)
  ) {
    eligibility.isEligible = false;
    eligibility.ineligibilityReason = IneligibilityReason.Other;
  }

  // in case of error, instead of redirecting to error page, set
  // eligibility false so user is redirected to pay bill (via login)
  if (error) {
    eligibility.isEligible = false;
    eligibility.ineligibilityReason = IneligibilityReason.NoPaymentMethod;
  }

  return {
    eligibility,
    loading: quickPayLoading,
    data: quickPayData,
    result: submitResult ? submitResult?.submitQuickPayment : undefined,
    handleQuickPaySubmit,
  };
};
