import React, { FunctionComponent } from 'react';
import { navigate } from 'gatsby';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Grid, Box } from '@material-ui/core';
import dayjs from 'dayjs';
import formatCurrency from 'format-currency';
import ROUTES from '../../routes';
import PGEButton from '../buttons/PGE-Button';
import {
  QuickPayConfirmation,
  QuickPayDetails,
} from '../../__generated__/pge-types';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { toDateStringFullMonthName } from '../../util/format';
import PaymentProfileIcon from '../paymentus/payment-profile-icon';
import { getAccountDisplayString } from '../paymentus/utils';
import PaymentLimitsFees from '../paymentus/payment-limits-fees';
import {
  getPaymentCategoryLabel,
  getPaymentMethodLabel,
} from '../../hooks/usePaymentus';
import { useIsMobile } from '../../util/style-utils';

const useStyles = makeStyles((theme: Theme) => ({
  serviceAddress: {
    background: 'white',
    color: colors.noirBlur,
    borderRadius: 5,
    padding: 15,
    marginTop: 20,
    marginBottom: 20,
  },
  cardStyle: {
    textAlign: 'left',
    color: colors.noirBlur,
  },
  borderSuccess: {
    border: `1px solid ${colors.shoreGreen}`,
  },
  cardTitle: {
    marginBottom: 15,
  },
  payButton: {
    // margin: 15,
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: colors.orange,
  },
  gridRow: {
    paddingTop: 15,
    paddingBottom: 10,
    borderBottom: '1px solid #EBEBEB',
  },
  firstRow: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  biggerFont: {
    fontSize: 20,
  },
  butSmallWhenMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  butEvenBiggerWhenMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  lastRow: {
    marginTop: 20,
  },
  changeLink: {
    textDecoration: 'none',
  },
  rightButCenterMobile: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

interface QuickPayConfirmProps {
  path?: string;
  data: QuickPayDetails | undefined;
  result?: QuickPayConfirmation | undefined;
  handleQuickPaySubmit: () => Promise<void>;
}

const QuickPayConfirm: FunctionComponent<QuickPayConfirmProps> = ({
  data,
  result,
  handleQuickPaySubmit,
}) => {
  const styles = useStyles();
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();

  if (result && result?.isSuccess) {
    return (
      <>
        <div className={styles.serviceAddress}>
          <Typography variant={'body1'}>
            {data?.serviceAddress?.split(',')[0]}
          </Typography>
        </div>
        <Card
          style={{ padding: 20 }}
          className={`${styles.cardStyle} ${styles.borderSuccess}`}
        >
          <Grid
            item
            container
            spacing={2}
            alignItems={'center'}
            direction={'row'}
          >
            <Grid item>
              <Typography variant={'h2'} style={{ color: colors.shoreGreen }}>
                <CheckCircleIcon fontSize="inherit" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'h2'} className={styles.cardTitle}>
                {t('PAYMENT_OF_AMOUNT_SENT', {
                  amount: `$${formatCurrency(
                    data?.currentCharges?.amountDue?.toString(),
                  )}`,
                })}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Typography variant={'body1'} style={{ lineHeight: '30px' }}>
            {t('CONFIRMATION_NUMBER')}: <b>{result.confirmationId}</b>
            <br />
            {t('WE_HAVE_SENT_A_CONFIRMATION_TO')}: <b>{data?.emailId}</b>
            <br />
            <br />
          </Typography>

          <Grid container justify="flex-end">
            <PGEButton
              variant={'contained'}
              color={'primary'}
              className={styles.payButton}
              onClick={() => navigate(ROUTES.HOME)}
            >
              {t('DONE')}
            </PGEButton>
          </Grid>
        </Card>
      </>
    );
  }
  return (
    <>
      <div className={styles.serviceAddress}>
        <Typography variant={'body1'}>
          {data?.serviceAddress?.split(',')[0]}
        </Typography>
      </div>
      <Card style={{ padding: 20 }} className={styles.cardStyle}>
        <Grid container direction="row" justify={'space-between'}>
          <Grid item>
            <Typography variant={'h2'} className={styles.cardTitle}>
              {t('VERIFY_PAYMENT')}
            </Typography>
          </Grid>
          <Grid item>
            <PaymentLimitsFees tabIndex={-1} />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justify="space-between"
          className={[styles.gridRow, styles.firstRow].join(' ')}
        >
          <Grid item md={4} xs={12}>
            <Typography variant={'body1'}>{t('PAYMENT_AMOUNT')}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography
              variant={'h3'}
              className={[
                styles.biggerFont,
                styles.butEvenBiggerWhenMobile,
              ].join(' ')}
            >
              ${formatCurrency(data?.currentCharges?.amountDue)}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography
              variant={'body1'}
              className={styles.rightButCenterMobile}
            >
              {t('DUE_ON')}&nbsp;
              {toDateStringFullMonthName(
                dayjs(
                  data?.currentCharges?.dueDate?.toString(),
                  'YYYY-MM-DD',
                ).toDate(),
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justify="space-between"
          className={styles.gridRow}
        >
          <Grid item md={4} sm={6} xs={6}>
            <Typography variant={'body1'}>{t('PAYMENT_DATE')}</Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            <Typography
              variant={'h3'}
              className={[styles.biggerFont, styles.butSmallWhenMobile].join(
                ' ',
              )}
            >
              {toDateStringFullMonthName(dayjs().toDate())}
            </Typography>
          </Grid>
          <Grid item md={4} sm={12} xs={12} />
        </Grid>

        <Grid
          container
          spacing={2}
          justify="space-between"
          className={styles.gridRow}
        >
          <Grid item md={4} sm={6} xs={6}>
            <Typography variant={'body1'}>
              {getPaymentMethodLabel(data?.paymentProfile?.type)}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            <Grid
              container
              item
              alignItems={'center'}
              direction={'row'}
              spacing={2}
            >
              <Grid item>
                <PaymentProfileIcon
                  type={data?.paymentProfile?.type}
                ></PaymentProfileIcon>
              </Grid>
              <Grid item>
                <Typography
                  variant={'h3'}
                  className={[
                    styles.biggerFont,
                    styles.butSmallWhenMobile,
                  ].join(' ')}
                >
                  {getAccountDisplayString(
                    data?.paymentProfile,
                    isMobile ? 7 : undefined,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Typography
              variant={'body1'}
              className={styles.rightButCenterMobile}
            >
              <a className={styles.changeLink} href={ROUTES.PAYMENT}>
                {t('CHANGE')}
              </a>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justify="space-between"
          className={styles.lastRow}
        >
          <Grid item md={12} sm={12}>
            <Box mt={2} mb={5}>
              <Typography component={'div'}>
                {richT('QUICKPAY_I_AUTHORIZE_PGE_TO_CHARGE', {
                  AMOUNT: `$${formatCurrency(
                    data?.currentCharges?.amountDue?.toString(),
                  )}`,
                  PAYMENT_CATEGORY: getPaymentCategoryLabel(
                    data?.paymentProfile?.type,
                  ),
                })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <PGEButton
            variant={'contained'}
            color={'primary'}
            className={styles.payButton}
            onClick={handleQuickPaySubmit}
          >
            {t('PAY_BILL')}
          </PGEButton>
        </Grid>
      </Card>
    </>
  );
};

export default QuickPayConfirm;
