import React, { FunctionComponent as FC, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import AuthLayout from '../components/auth-layout';
import { useQuickPay, IneligibilityReason } from '../hooks/useQuickPay';
import { useTranslation } from '../hooks/useTranslation';
import QuickPayConfirm from '../components/quick-pay-confirm';
import NavPaneWithSignIn from '../components/nav-pane/NavPaneWithSignIn';
import { usePlannedDownTimeRedirect } from '../hooks/usePlannedDownTimeRedirect';
import { NotificationsContext } from '../providers/NotificationsProvider';
import ROUTES from '../routes';
import { Router, navigate } from '@reach/router';
import Backdrop from '../components/backdrop';

export const QuickPayPage: FC = () => {
  usePlannedDownTimeRedirect();
  const notificationContext = useContext(NotificationsContext);
  const { t } = useTranslation();
  const {
    eligibility,
    loading,
    data,
    handleQuickPaySubmit,
    result,
  } = useQuickPay();

  useEffect(() => {
    const isTokenError =
      !eligibility.isEligible &&
      (eligibility.ineligibilityReason === IneligibilityReason.NoToken ||
        eligibility.ineligibilityReason ===
          IneligibilityReason.NoPaymentMethod);

    if (isTokenError || (result && !result?.isSuccess)) {
      notificationContext.setState({
        isOpen: true,
        message: isTokenError
          ? eligibility.ineligibilityReason ===
            IneligibilityReason.NoPaymentMethod
            ? t('QP_NO_PAYMENT_METHOD_ERROR')
            : t('NO_TOKEN_ERROR')
          : t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        severity: 'error',
      });
    } else if (result && result?.isSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.QUICK_PAY_SUCCESS);
    }
  }, [
    eligibility?.isEligible,
    eligibility?.ineligibilityReason,
    result?.isSuccess,
  ]);

  if (loading || !eligibility.isEligible) {
    if (eligibility.ineligibilityReason === IneligibilityReason.AutoPay) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.VIEW_BILL);
    }
    if (
      eligibility.ineligibilityReason === IneligibilityReason.Other ||
      eligibility.ineligibilityReason === IneligibilityReason.NoToken ||
      eligibility.ineligibilityReason === IneligibilityReason.NoPaymentMethod
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.PAYMENT);
    }

    return <Backdrop forceOpen />;
  }

  return (
    <AuthLayout justify="left" sideNav={<NavPaneWithSignIn />}>
      <Helmet>
        <title>{t('QUICK_PAY')}</title>
      </Helmet>
      <Typography variant="h1">{t('QUICK_PAY')}</Typography>
      <Router basepath="/">
        <QuickPayConfirm
          path={ROUTES.QUICK_PAY}
          data={data}
          handleQuickPaySubmit={handleQuickPaySubmit}
          result={result}
        />
        <QuickPayConfirm
          path={ROUTES.QUICK_PAY_SUCCESS}
          data={data}
          handleQuickPaySubmit={handleQuickPaySubmit}
          result={result}
        />
      </Router>
    </AuthLayout>
  );
};

export default QuickPayPage;
